<template>
  <b-tab :title="$t('LINK_PAGAMENTO.ABA_RECORRENCIA')" class="p-0">
    <b-card>
      <div class="row">
        <input-autocomplete
          id="input-estabelecimento"
          :disabled="form.id != null"
          class="col-12 col-md-6"
          :label="$t('LINK_PAGAMENTO.ESTABELECIMENTO')"
          :options="opcoes.estabelecimentos"
          v-model="form.estabelecimentoId"
          ref="form-Recorrencia-estabelecimento"
          required
        />
        <input-autocomplete
          id="input-plano"
          :disabled="form.id != null"
          class="col-12 col-md-6"
          :label="$t('LINK_PAGAMENTO.PLANO')"
          :options="opcoes.planos"
          v-model="form.planoId"
          ref="form-Recorrencia-plano"
          required
        >
          <template #append>
            <b-button
              id="btn-plano"
              v-if="!form.id && permissaoPlano"
              @click="novoPlano"
              class="ml-2"
              variant="outline-primary"
            >
              {{ $t("LINK_PAGAMENTO.NOVO_PLANO") }}
            </b-button>
          </template>
        </input-autocomplete>
        <input-autocomplete
          id="input-cliente"
          :disabled="form.id != null"
          class="col-12"
          :label="$t('LINK_PAGAMENTO.CLIENTE')"
          :options="opcoes.clientes"
          v-model="form.clienteId"
          ref="form-Recorrencia-cliente"
          required
        >
          <template #append>
            <b-button
              id="btn-cliente"
              v-if="!form.id && permissaoCliente"
              @click="novoCliente"
              class="ml-2"
              variant="outline-primary"
            >
              {{ $t("LINK_PAGAMENTO.NOVO_CLIENTE") }}
            </b-button>
          </template>
        </input-autocomplete>
        <input-text class="col-12" v-if="form.id" v-model="form.link" readonly>
          <template #append>
            <b-button
              id="btn-link-url"
              @click="copiar"
              class="ml-2"
              variant="outline-valorem-ciano"
            >
              <i class="fas fa-copy"></i>
              {{ $t("LINK_PAGAMENTO.COPIAR_LINK") }}
            </b-button>
          </template>
        </input-text>
        <div class="col-12 d-flex align-items-center justify-content-end">
          <b-button
            id="btn-cancelar"
            variant="outline-primary"
            @click="$emit('cancelar')"
          >
            {{ $t("LINK_PAGAMENTO.CANCELAR") }}
          </b-button>
          <b-button
            id="btn-salvar"
            v-if="!form.id && permissaoLink"
            variant="primary"
            class="ml-2"
            @click="$emit('salvar')"
          >
            {{ $t("LINK_PAGAMENTO.ENVIAR") }}
          </b-button>
        </div>
      </div>
    </b-card>
    <valorem-modal
      id="modal-cliente"
      ref="modal-cliente"
      :titulo-modal="$t('CLIENTES.TITULO_FORMULARIO_NOVO')"
      @ok="salvarCliente"
    >
      <cadastro-cliente modal ref="cadastro-cliente" />
    </valorem-modal>
    <valorem-modal
      id="modal-plano"
      ref="modal-plano"
      :titulo-modal="$t('PLANOS.TITULO_FORMULARIO_NOVO')"
      @ok="salvarPlano"
    >
      <cadastro-plano modal ref="cadastro-plano" />
    </valorem-modal>
  </b-tab>
</template>
<script>
import { InputAutocomplete, InputText } from "@/components/inputs";

import ClientesService from "@/common/services/clientes/clientes.service";
import MinhaContaService from "@/common/services/usuario/minha-conta.service";
import PlanoService from "@/common/services/planos/planos.service";

import CadastroCliente from "@/views/clientes/formulario";
import CadastroPlano from "@/views/planos/Formulario";
import helpers from "../../../common/utils/helpers";
export default {
  props: {
    form: {
      required: true,
    },
  },
  components: {
    InputText,
    InputAutocomplete,
    CadastroCliente,
    CadastroPlano,
  },
  data() {
    return {
      opcoes: {
        estabelecimentos: [],
        planos: [],
        clientes: [],
      },
    };
  },
  methods: {
    acessar: function () {
      window.open(this.form.link);
    },
    novoCliente: function () {
      this.$refs["modal-cliente"].abrirModal();
    },
    salvarCliente: function () {
      this.$refs["cadastro-cliente"].salvar().then(() => {
        this.$refs["modal-cliente"].fecharModal();
        this.buscarClientes();
      });
    },
    novoPlano: function () {
      this.$refs["modal-plano"].abrirModal();
    },
    salvarPlano: function () {
      this.$refs["cadastro-plano"].salvar().then(() => {
        this.$refs["modal-plano"].fecharModal();
        this.buscarPlanos();
      });
    },
    buscarClientes: function () {
      ClientesService.dropdown(this.form.estabelecimentoId).then((res) => {
        this.opcoes.clientes = res.data.data.clientes.map((el) => {
          return {
            value: el.id,
            text: el.nome,
          };
        });
      });
    },
    buscarEstabelecimentos: function () {
      MinhaContaService.buscarEstabelecimento().then((res) => {
        this.opcoes.estabelecimentos = res.data.data.estabelecimentos
          .map((el) => {
            return {
              value: el.id,
              text: el.nome,
              enviaLinkRecorrencia: el.enviaLinkRecorrencia,
            };
          })
          .filter((el) => {
            return el.enviaLinkRecorrencia;
          });
      });
    },
    buscarPlanos: function () {
      PlanoService.dropdown(this.form.estabelecimentoId).then((res) => {
        this.opcoes.planos = res.data.data.planos.map((el) => {
          return {
            value: el.id,
            text: el.nome,
          };
        });
      });
    },
    copiar: function () {
      var copyTextarea = this.form.link;

      const textArea = document.createElement("textarea");
      textArea.textContent = copyTextarea;
      document.body.append(textArea);
      textArea.select();
      document.execCommand("copy");

      textArea.remove();
    },
  },
  watch: {
    "form.estabelecimentoId": function () {
      this.buscarClientes();
      this.buscarPlanos();
    },
  },
  computed: {
    permissaoLink: function () {
      return helpers.validarAcesso("LinkPagamento").criar;
    },
    permissaoCliente: function () {
      return helpers.validarAcesso("Cliente").criar;
    },
    permissaoPlano: function () {
      return helpers.validarAcesso("Plano").criar;
    },
  },
  mounted() {
    this.buscarClientes();
    this.buscarEstabelecimentos();
    this.buscarPlanos();
  },
};
</script>
<style lang=""></style>
