<template lang="">
  <div>
    <b-tabs class="abas-formulario" card>        
      <avista
        id="tab-a-vista"
        v-if="id ? form.tipoLinkPagamento == 'AVistaParcelado' : true"
        @cancelar="cancelar"
        @salvar="salvar('AVistaParcelado')"
        ref="AVistaParcelado"
        :form="form"
      />
      <recorrencia
        id="tab-recorrencia"
        v-if="id ? form.tipoLinkPagamento == 'Recorrencia' : estabelecimentoSelecionadoPodeAntecipar"
        @cancelar="cancelar"
        @salvar="salvar('Recorrencia')"
        ref="Recorrencia"
        :form="form"
      />
    </b-tabs>
  </div>
</template>
<script>
import { START_LOADING, STOP_LOADING } from "@/store/Store";
import { DEFINIR_BREADCRUMB } from "@/store/breadcrumbs.module";
import LinkPagamentoService from "@/common/services/link_pagamento/link_pagamento.service";
import avista from "./components/avista.vue";
import recorrencia from "./components/recorrencia.vue";
import cloneDeep from "lodash.clonedeep";
import helpers from "../../common/utils/helpers";
import MinhaContaService from "@/common/services/usuario/minha-conta.service";
export default {
  props: ["id"],
  components: {
    avista,
    recorrencia,
  },
  data: function () {
    return {
      form: {
        clienteId: null,
        planoId: null,
        estabelecimentoId: null,

        descricao: "",
        valor: "",
        desconto: 0,
        parcelas: null,
        emailCliente: null,

        tipoLinkPagamento: "AVistaParcelado",
        tipoCobranca: "ValorAvulso",
        tipoCliente: "ClienteCadastrado",

        linkPagamentoProdutos: [],
      },
      estabelecimentoAntigo: null,
      estabelecimentos: [],
    };
  },
  methods: {
    salvar: function (tipoLink) {
      let form = cloneDeep(this.form);
      form.tipoLinkPagamento = tipoLink;
      this.confirmar(
        this.$t(`LINK_PAGAMENTO.TITULO_SALVAR`),
        this.$t("GERAL.MENSAGEM_CONFIRMAR_ACAO")
      ).then((confirmado) => {
        if (!confirmado) return;

        if (!this.$refs[tipoLink].validarFormulario(`form-${tipoLink}-`))
          return false;

        this.$store.dispatch(START_LOADING);
        LinkPagamentoService.salvar(form)
          .then(() => {
            this.alertaSucesso(this.$t("LINK_PAGAMENTO.MENSAGEM_SUCESSO")).then(
              () => {
                this.$router.push({ name: "link-pagamento" });
              }
            );
          })
          .finally(() => {
            this.$store.dispatch(STOP_LOADING);
          });
      });
    },
    cancelar: function () {
      this.$router.push({ name: "link-pagamento" });
    },
    buscar: function () {
      this.$store.dispatch(START_LOADING);
      LinkPagamentoService.buscar(this.id)
        .then((res) => {
          this.form = res.data.data;
          this.form.valor = helpers.formatarValor(this.form.valor);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    buscarEstabelecimentos: function () {
      MinhaContaService.buscarEstabelecimento().then((res) => {
        this.estabelecimentos = res.data.data.estabelecimentos.map((el) => {
          return {
            value: el.id,
            text: el.nome,
            enviaLinkRecorrencia: el.enviaLinkRecorrencia,
          };
        });
      });
    },
  },
  mounted() {
    this.$store.dispatch(DEFINIR_BREADCRUMB, [
      { titulo: this.$t("LINK_PAGAMENTO.TITULO_LISTA"), to: "/link_pagamento" },
      {
        titulo: this.$t(
          `LINK_PAGAMENTO.TITULO_FORMULARIO_${!this.id ? "NOVO" : "EDICAO"}`
        ),
      },
    ]);

    this.buscarEstabelecimentos();

    if (this.id) {
      this.buscar();
    } else {
      this.form.estabelecimentoId =
        this.$store.getters["estabelecimentoSelecionado"];
    }
  },
  watch: {
    "form.estabelecimentoId": function (novo, antigo) {
      if (
        this.form.linkPagamentoProdutos.length > 0 &&
        antigo != "" &&
        antigo != null &&
        this.estabelecimentoAntigo != novo
      ) {
        this.confirmar(
          "Aviso",
          "Alterar o estabelecimento resultará na remoção dos produtos adicionados. Deseja executar essa ação?"
        ).then((confirmado) => {
          if (!confirmado) {
            this.estabelecimentoAntigo = antigo;
            this.form.estabelecimentoId = antigo;
            return;
          }

          this.form.linkPagamentoProdutos = [];
          this.form.clienteId = null;
          this.form.planoId = null;
        });
      } else {
        if (!this.form.id) {
          this.form.clienteId = null;
          this.form.planoId = null;
        }
      }
    },
  },
  computed: {
    estabelecimentoSelecionadoPodeAntecipar: function () {
      if (this.form.id) return true;

      return this.estabelecimentos.find(
        (el) => el.value == this.form.estabelecimentoId
      )?.enviaLinkRecorrencia;
    },
  },
};
</script>
<style lang="scss"></style>
