<template lang="">
  <div>
    <b-card :no-body="modal">
      <div class="row pr-1">
        <div class="col-12" v-if="!modal">
          <h4 class="card-title mb-3">
            {{ $t(`CLIENTES.TITULO_FORMULARIO_${!id ? "NOVO" : "EDICAO"}`) }}
          </h4>
        </div>
        <div class="col-12">
          <div class="row">
            <input-text
              id="input-nome"
              class="col-12 col-md-6"
              :label="$t('CLIENTES.TABELA.NOME')"
              v-model="form.nome"
              ref="nome"
            />
            <input-text
              id="input-email"
              class="col-12 col-md-6"
              :label="$t('CLIENTES.TABELA.EMAIL')"
              v-model="form.email"
              ref="email"
              type="email"
              required
            />
            <input-mask
              id="input-cpf"
              class="col-12 col-md-3"
              :label="$t('CLIENTES.TABELA.CPF')"
              :mask="['###.###.###-##']"
              type="cpf"
              v-model="form.cpf"
              ref="cpf"
              required
            />
            <input-mask
              id="input-contato"
              class="col-12 col-md-3"
              :label="$t('CLIENTES.TABELA.CONTATO')"
              :mask="['(##)####-####', '(##)#####-####']"
              v-model="form.numeroTelefone"
              ref="numeroTelefone"
              required
            />
            <input-select
              id="input-estabelecimento"
              class="col-12 col-md-6"
              :label="$t('CLIENTES.TABELA.ESTABELECIMENTO')"
              :options="opcoes.estabelecimentos"
              v-model="form.estabelecimentoId"
              ref="estabelecimentoId"
              required
            >
              <template #label>
                <div class="d-inline-flex align-items-center">
                  <span class="mr-2">
                    {{ $t("CLIENTES.TABELA.ESTABELECIMENTO") }}
                  </span>
                  <feather
                    type="help-circle"
                    size="14"
                    v-b-tooltip.hover
                    :title="$t('CLIENTES.TOOLTIP')"
                  ></feather>
                </div>
              </template>
            </input-select>
          </div>
          <hr>
          <div class="row">
            <input-mask
              id="input-cep"
              class="col-12 col-md-2"
              :label="$t('ESTABELECIMENTOS.CEP')"
              v-model="form.endereco.cep"
              @change="consultaCep"
              :mask="['#####-###']"
              ref="cep"
              required
            />
            <input-text
              id="input-endereco"
              class="col-12 col-md-5"
              :label="$t('ESTABELECIMENTOS.ENDERECO')"
              v-model="form.endereco.logradouro"
              ref="logradouro"
              required
            />
            <input-text
              id="input-numero"
              class="col-12 col-md-2"
              :label="$t('ESTABELECIMENTOS.NUMERO')"
              v-model="form.endereco.numero"
              ref="numero"
              required
            />
            <input-text
              id="input-complemento"
              class="col-12 col-md-3"
              :label="$t('ESTABELECIMENTOS.COMPLEMENTO')"
              v-model="form.endereco.complemento"
              ref="complemento"
            />
            <input-text
              id="input-bairro"
              class="col-12 col-md-3"
              :label="$t('ESTABELECIMENTOS.BAIRRO')"
              v-model="form.endereco.bairro"
              required
              ref="bairro"
            />
            <input-text
              id="input-cidade"
              class="col-12 col-md-3"
              :label="$t('ESTABELECIMENTOS.CIDADE')"
              v-model="form.endereco.cidade"
              required
              ref="cidade"
            />
            <input-mask
              id="input-estado"
              class="col-12 col-md-3"
              :mask="['AA']"
              :label="$t('ESTABELECIMENTOS.ESTADO')"
              v-model="form.endereco.uf"
              required
              :max="2"
              :min="2"
              ref="uf"
            />
            <input-select
              id="input-pais"
              class="col-12 col-md-3"
              :label="$t('ESTABELECIMENTOS.PAIS')"
              :options="opcoes.paises"
              required
              v-model="form.endereco.pais"
              ref="paises"
            />
          </div>
        </div>

        <div v-if="!modal" class="col-12 mt-4 d-flex justify-content-end">
          <b-button
            id="btn-cancelar"
            @click="cancelar"
            variant="outline-success"
          >
            Cancelar
          </b-button>
          <b-button
            id="btn-salvar"
            @click="salvar"
            v-if="form.id ? permissao.editar : permissao.criar"
            class="ml-2"
            variant="success"
          >
            Salvar
          </b-button>
        </div>
      </div>
    </b-card>
  </div>
</template>
<script>
import { InputText, InputMask, InputSelect } from "@/components/inputs";
import ClientesService from "@/common/services/clientes/clientes.service";
import MinhaContaService from "@/common/services/usuario/minha-conta.service";
import { START_LOADING, STOP_LOADING } from "@/store/Store";
import { DEFINIR_BREADCRUMB } from "@/store/breadcrumbs.module";
import helpers from "@/common/utils/helpers";
import cloneDeep from "lodash.clonedeep";
import cep from 'cep-promise'

export default {
  props: {
    id: {},
    modal: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    InputText,
    InputMask,
    InputSelect,
  },
  data: function () {
    return {
      form: {
        nome: "",
        cpf: "",
        numeroTelefone: "",
        email: "",
        estabelecimentoId: "",
        endereco: {
          cep: null,
          logradouro: null,
          numero: null,
          complemento: null,
          bairro: null,
          cidade: null,
          uf: null,
          pais: "Brasil",
        },
      },
      opcoes: {
        estabelecimentos: [],
        paises: [{ value: "Brasil", text: "Brasil" }],
      },
    };
  },
  methods: {
    salvar: function () {
      return new Promise((resolve) => {
        this.confirmar(
          this.$t(`CLIENTES.TITULO_SALVAR`),
          this.$t("GERAL.MENSAGEM_CONFIRMAR_ACAO")
        ).then((confirmado) => {
          if (!confirmado) return false;

          if (!this.validarFormulario()) return false;

          this.$store.dispatch(START_LOADING);
          let form = cloneDeep(this.form);
          form.cpf = helpers.removerMascara(form.cpf);
          form.numeroTelefone = helpers.removerMascara(form.numeroTelefone);
          ClientesService.salvar(form)
            .then(() => {
              this.alertaSucesso(this.$t("CLIENTES.MENSAGEM_SUCESSO")).then(
                () => {
                  if (!this.modal) this.$router.push({ name: "clientes" });
                  resolve();
                }
              );
            })
            .finally(() => {
              this.$store.dispatch(STOP_LOADING);
            });
        });
      });
    },
    cancelar: function () {
      this.$router.push({ name: "clientes" });
    },
    buscar: function () {
      this.$store.dispatch(START_LOADING);
      ClientesService.buscar(this.id)
        .then((res) => {
          this.form = res.data.data;
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    buscarEstabelecimentos: function () {
      this.$store.dispatch(START_LOADING);
      MinhaContaService.buscarEstabelecimento()
        .then((res) => {
          this.opcoes.estabelecimentos = res.data.data.estabelecimentos.map(
            (el) => {
              return {
                value: el.id,
                text: el.nome,
              };
            }
          );
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    consultaCep: function () {
      const value = helpers.removerMascara(this.form.endereco.cep);

      var validacep = /^[0-9]{8}$/;
      if(validacep.test(value)){
        cep(value).then((res)=>{
          let endereco = {};
          endereco.cep = res.cep;
          endereco.logradouro = res.street;
          endereco.bairro = res.neighborhood;
          endereco.cidade = res.city;
          endereco.uf = res.state;
          endereco.pais = 'Brasil';
          this.form.endereco = endereco;
        });
      }
    },
  },
  mounted() {
    if (!this.modal)
      this.$store.dispatch(DEFINIR_BREADCRUMB, [
        { titulo: this.$t("CLIENTES.TITULO_LISTA"), to: "/clientes" },
        {
          titulo: this.$t(
            `CLIENTES.TITULO_FORMULARIO_${!this.id ? "NOVO" : "EDICAO"}`
          ),
        },
      ]);
    this.buscarEstabelecimentos();
    if(this.id){
      this.buscar();
    }else{            
      this.form.estabelecimentoId = this.$store.getters['estabelecimentoSelecionado'];
    }
  },
  computed: {
    permissao: function () {
      return helpers.validarAcesso("Cliente");
    },
  },
};
</script>
<style lang=""></style>
