<template>
  <b-tab :title="$t('LINK_PAGAMENTO.ABA_AVISTA')" class="p-0">
    <b-card>
      <div class="row">
        <input-radio
          id="input-tipo-cobranca"
          :disabled="form.id"
          class="col-12"
          :label="$t('LINK_PAGAMENTO.TIPO_COBRANCA')"
          :options="opcoes.tipoCobranca"
          v-model="form.tipoCobranca"
        />
        <div class="col-12">
          <span class="row" v-if="form.tipoCobranca == 'ValorAvulso'">
            <input-currency
              id="input-valor-avulso-valor"
              :disabled="form.id"
              class="col-12 col-md-2"
              :label="$t('LINK_PAGAMENTO.VALOR_AVULSO')"
              v-model="form.valor"
              ref="form-AVistaParcelado-valor"
              required
            />
            <input-select
              id="input-valor-avulso-parcelas"
              :disabled="form.id"
              class="col-12 col-md-4"
              :label="$t('LINK_PAGAMENTO.PARCELAS')"
              :options="parcelas"
              v-model="form.parcelas"
              ref="form-AVistaParcelado-parcela"
              required
            />
            <input-text
              id="input-valor-avulso-descricao"
              :disabled="form.id"
              class="col-12 col-md-6"
              :label="$t('LINK_PAGAMENTO.DESCRICAO')"
              v-model="form.descricao"
              ref="form-AVistaParcelado-descricao-1"
              required
            />
          </span>

          <span class="row" v-if="form.tipoCobranca == 'Produto'">
            <input-text
              id="input-produto-descricao"
              :disabled="form.id"
              class="col-12"
              :label="$t('LINK_PAGAMENTO.DESCRICAO')"
              v-model="form.descricao"
              ref="form-AVistaParcelado-descricao-2"
              required
            />
            <template v-if="!form.id">
              <input-autocomplete
                id="input-produto-nome"
                class="col-12 col-md-6"
                :label="$t('LINK_PAGAMENTO.NOME_PRODUTO')"
                :options="opcoes.produtos"
                v-model="subform.produto"
                ref="subform-produto"
                required
              />
              <input-text
                id="input-produto-quantidade"
                class="col-12 col-md-2"
                :label="$t('LINK_PAGAMENTO.QUANTIDADE')"
                v-model="subform.quantidade"
                ref="subform-quantidade"
                required
                :min="0"
              />
              <input-currency
                id="input-produto-valor"
                class="col-12 col-md-4"
                readonly
                :label="$t('LINK_PAGAMENTO.VALOR')"
                v-model="subform.valor"
                ref="subform-valor"
                required
              >
                <template #append>
                  <b-button
                    id="btn-adicionar-produto"
                    class="ml-2"
                    variant="info"
                    @click="adicionarProduto"
                  >
                    <div class="d-flex align-items-center">
                      <feather type="plus" size="20" class="mr-2" />
                      <span>{{ $t("LINK_PAGAMENTO.ADICIONAR_SACOLA") }}</span>
                    </div>
                  </b-button>
                </template>
              </input-currency>
              <input-currency
                id="input-desconto"
                class="col-12"
                :label="$t('LINK_PAGAMENTO.DESCONTO')"
                v-model="desconto"
                ref="desconto-valor"
                required
              >
                <template #append>
                  <b-button
                    class="ml-2"
                    variant="info"
                    @click="adicionarDesconto"
                  >
                    <div class="d-flex align-items-center">
                      <feather type="plus" size="20" class="mr-2" />
                      <span>{{ $t("LINK_PAGAMENTO.ADICIONAR_SACOLA") }}</span>
                    </div>
                  </b-button>
                </template>
              </input-currency>
            </template>
            <valorem-tabela
              id="tabela-produtos"
              class="col-12 mt-2"
              :colunas="colunasProduto"
              v-model="tabelaProdutos"
              :sem-paginacao="false"
              rodape
            >
              <template #cell(acoes)="data">
                <div>
                  <b-button
                    v-if="!form.id"
                    @click="removerProduto(data.item)"
                    variant="flat-valorem-vermelho"
                    class="btn-remover-produto w-100"
                    >Remover</b-button
                  >
                </div>
              </template>

              <template #foot(codigo)>
                <span>{{ $t("LINK_PAGAMENTO.VALOR_TOTAL") }}</span>
              </template>
              <template #foot(valor)>
                <span>{{ valorTotal }}</span>
              </template>
              <template #foot()>
                <span></span>
              </template>
            </valorem-tabela>
            <input-select
              id="input-parcela"
              :disabled="form.id"
              class="col-12 col-md-4"
              :label="$t('LINK_PAGAMENTO.PARCELAS')"
              :options="parcelas"
              v-model="form.parcelas"
              ref="form-AVistaParcelado-parcelas"
              required
            />
          </span>
        </div>
        <div class="col-12"><hr /></div>
        <div class="col-12">
          <div class="row">
            <input-radio
              id="input-tipo-cliente"
              :disabled="form.id"
              class="col-12"
              :label="$t('LINK_PAGAMENTO.DADOS_ENVIO')"
              :options="opcoes.tipoCliente"
              v-model="form.tipoCliente"
              ref="form-AVistaParcelado-dadosEnvio"
              required
            />
            <input-text
              id="input-email"
              :disabled="form.id"
              class="col-12 col-md-6"
              :label="$t('LINK_PAGAMENTO.EMAIL')"
              type="email"
              v-if="form.tipoCliente == 'NovoCliente'"
              v-model="form.emailCliente"
              ref="form-AVistaParcelado-email"
            />
            <input-autocomplete
              id="input-cliente"
              :disabled="form.id"
              class="col-12 col-md-6"
              :label="$t('LINK_PAGAMENTO.CLIENTE')"
              v-if="form.tipoCliente == 'ClienteCadastrado'"
              :options="opcoes.clientes"
              v-model="form.clienteId"
              ref="form-AVistaParcelado-cliente"
              required
            >
              <template #append>
                <b-button
                  id="btn-novo-cliente"
                  v-if="!form.id && permissaoCliente"
                  @click="novoCliente"
                  class="ml-2"
                  variant="outline-primary"
                >
                  {{ $t("LINK_PAGAMENTO.NOVO_CLIENTE") }}
                </b-button>
              </template>
            </input-autocomplete>
            <input-text
              id="input-validade"
              :disabled="form.id"
              class="col-12 col-md-6"
              :label="$t('LINK_PAGAMENTO.VALIDADE')"
              v-model="form.validade"
              ref="form-AVistaParcelado-validade"
              :type="Number"
              :min="0"
            />
            <input-autocomplete
              id="input-estabelecimento"
              :disabled="form.id"
              class="col-12 col-md-6"
              :label="$t('LINK_PAGAMENTO.ESTABELECIMENTO')"
              :options="opcoes.estabelecimentos"
              v-model="form.estabelecimentoId"
              ref="form-AVistaParcelado-estabelecimento"
              required
            />
          </div>
        </div>
        <input-text
          class="col-12 view-link-url"
          v-if="form.id"
          v-model="form.link"
          readonly
        >
          <template #append>
            <b-button
              id="btn-link-url"
              @click="copiar"
              class="ml-2"
              variant="outline-valorem-ciano"
            >
              <i class="fas fa-copy"></i>
              {{ $t("LINK_PAGAMENTO.COPIAR_LINK") }}
            </b-button>
          </template>
        </input-text>

        <div class="col-12 d-flex align-items-center justify-content-end">
          <b-button
            id="btn-cancelar"
            variant="outline-primary"
            @click="$emit('cancelar')"
          >
            {{ $t("LINK_PAGAMENTO.CANCELAR") }}
          </b-button>
          <b-button
            id="btn-salvar"
            v-if="!form.id && permissaoLink"
            variant="primary"
            class="ml-2"
            @click="$emit('salvar')"
          >
            {{ $t("LINK_PAGAMENTO.ENVIAR") }}
          </b-button>
        </div>
      </div>
    </b-card>
    <valorem-modal
      id="modal-cliente"
      ref="modal-cliente"
      :titulo-modal="$t('CLIENTES.TITULO_FORMULARIO_NOVO')"
      @ok="salvarCliente"
    >
      <cadastro-cliente modal ref="cadastro-cliente" />
    </valorem-modal>
  </b-tab>
</template>
<script>
import {
  InputText,
  InputAutocomplete,
  InputRadio,
  InputCurrency,
  InputSelect,
} from "@/components/inputs";
import helpers from "@/common/utils/helpers";
import cloneDeep from "lodash.clonedeep";

import ProdutosService from "@/common/services/produtos/produtos.service";
import ClientesService from "@/common/services/clientes/clientes.service";
import MinhaContaService from "@/common/services/usuario/minha-conta.service";

import CadastroCliente from "@/views/clientes/formulario";
export default {
  props: {
    form: {
      required: true,
    },
  },
  components: {
    InputText,
    InputAutocomplete,
    InputRadio,
    InputCurrency,
    InputSelect,
    CadastroCliente,
  },
  data() {
    return {
      subform: {
        produto: null,
        quantidade: null,
        valor: null,
      },
      desconto: null,
      opcoes: {
        tipoCobranca: helpers.TipoLinkPagamentoCobranca,
        tipoCliente: helpers.TipoLinkPagamentoCliente,
        produtos: [],
        clientes: [],
        estabelecimentos: [],
      },
      colunasProduto: [
        { key: "codigo", label: "#" },
        {
          key: "produtoNome",
          label: this.$t("LINK_PAGAMENTO.NOME_PRODUTO"),
          thClass: "w-50",
        },
        {
          key: "valor",
          label: this.$t("LINK_PAGAMENTO.VALOR"),
          formatter: (v) => {
            if (v) return helpers.formatarValor(v);
          },
        },
        { key: "quantidade", label: this.$t("LINK_PAGAMENTO.QUANTIDADE") },
        { key: "acoes", label: "" },
      ],
      numeroMaximoParcelas: null,
    };
  },
  methods: {
    acessar: function () {
      window.open(this.form.link);
    },
    novoCliente: function () {
      this.$refs["modal-cliente"].abrirModal();
    },
    salvarCliente: function () {
      this.$refs["cadastro-cliente"].salvar().then(() => {
        this.$refs["modal-cliente"].fecharModal();
        this.buscarClientes();
      });
    },
    adicionarProduto: function () {
      if (!this.validarFormulario("subform-")) return;

      let subform = cloneDeep(this.subform);

      let produto = {
        codigo: subform.produto.codigo,
        produtoId: subform.produto.value,
        produtoNome: subform.produto.text,
        quantidade: subform.quantidade.replace(",", "."),
        valor: helpers.removerValor(subform.valor),
      };

      this.form.linkPagamentoProdutos.push(produto);

      this.limparFormulario("subform-");
    },
    removerProduto: function (item) {
      if (item.produtoId == "-") {
        this.form.desconto = 0;
      } else {
        let itemExcluir = this.form.linkPagamentoProdutos.find((el) => {
          return (
            el.produtoId == item.produtoId &&
            el.quantidade == item.quantidade &&
            el.valor == item.valor
          );
        });
        this.form.linkPagamentoProdutos.splice(
          this.form.linkPagamentoProdutos.indexOf(itemExcluir),
          1
        );
      }
    },
    adicionarDesconto: function () {
      if (!this.validarFormulario("desconto-")) return;

      let desconto = cloneDeep(this.desconto);

      this.form.desconto = helpers.removerValor(desconto);

      this.limparFormulario("desconto-");
    },
    buscarProdutos: function () {
      this.opcoes.produtos = [];
      ProdutosService.dropdown(this.form.estabelecimentoId).then((res) => {
        this.opcoes.produtos = res.data.data.produtos.map((el) => {
          return {
            value: {
              value: el.id,
              text: el.nome,
              valor: el.valor,
              codigo: el.codigo,
            },
            text: `${el.codigo} - ${el.nome}`,
          };
        });
      });
    },
    buscarClientes: function () {
      ClientesService.dropdown(this.form.estabelecimentoId).then((res) => {
        this.opcoes.clientes = res.data.data.clientes.map((el) => {
          return {
            value: el.id,
            text: el.nome,
          };
        });
      });
    },
    buscarEstabelecimentos: function () {
      MinhaContaService.buscarEstabelecimento().then((res) => {
        this.opcoes.estabelecimentos = res.data.data.estabelecimentos.map(
          (el) => {
            return {
              value: el.id,
              text: el.nome,
            };
          }
        );
      });
    },
    copiar: function () {
      var copyTextarea = this.form.link;

      const textArea = document.createElement("textarea");
      textArea.textContent = copyTextarea;
      document.body.append(textArea);
      textArea.select();
      document.execCommand("copy");

      textArea.remove();
    },
  },
  computed: {
    tabelaProdutos: function () {
      let tabela = [...this.form.linkPagamentoProdutos];
      if (this.form.desconto)
        tabela.push({
          codigo: "-",
          produtoId: "-",
          produtoNome: "Desconto",
          valor: this.form.desconto,
          quantidade: "-",
        });

      return tabela;
    },
    valorTotal: function () {
      let produtos = this.form.linkPagamentoProdutos.reduce(
        (total, produto) => {
          return (
            total +
            parseFloat(produto.valor) *
              parseFloat(`${produto.quantidade}`.replace(",", "."))
          );
        },
        0
      );
      let descontos = this.form.desconto;

      return helpers.formatarValor(produtos - descontos);
    },
    permissaoLink: function () {
      return helpers.validarAcesso("LinkPagamento").criar;
    },
    permissaoCliente: function () {
      return helpers.validarAcesso("Cliente").criar;
    },
    parcelas: function () {
      let valor = helpers.removerValor(`${this.valorTotal}`);
      if (this.form.tipoCobranca == "ValorAvulso")
        valor = helpers.removerValor(`${this.form.valor}`);
      return helpers.gerarParcelas(valor, this.numeroMaximoParcelas);
    },
  },
  watch: {
    "subform.produto": function (valor) {
      this.subform.valor = helpers.formatarValor(valor.valor);
    },
    "form.estabelecimentoId": function () {
      this.buscarProdutos();
      this.buscarClientes();
    },
  },
  mounted() {
    this.buscarEstabelecimentos();
    this.numeroMaximoParcelas =
      this.$store.state.autenticacao.numeroMaximoParcelaNoEstabelecimento;

    this.buscarProdutos();
    this.buscarClientes();
  },
};
</script>
<style scoped>
</style>
